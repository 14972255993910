.container {
    background-color: var(--footer);
    display: flex;
    justify-content: space-between;
    padding: 2em;
    padding-bottom: 1em;
    overflow: hidden;
    position: relative;
    max-height: 240px;
    /* min-width: 860px; NEEDS TO BE GLOBAL */
}

.text, .copyright {
    color: var(--banner);
}

.text {
    font-size: 16px;
}

.copyright {
    text-shadow: 0 4px 4px #00000040;
    font-size: 20px;
}

.universityLogo {
    float: right;
    background-color: rgb(226, 240, 243, 0.8);
    padding: 1em;
    border-radius: 1em;
}

.universityLogo:first-child {
    padding-bottom: 1em;
}

.siteLogo {
    width: 160px;
}

.nsfLogo {
    width: 80px;
}

.grantContainer, .infoContainer {
   text-align: center;
}

.universitiesContainer {
    z-index: 10;
}

.linesContainer {
    position: absolute;
    left: 50%;
    z-index: 1;
}