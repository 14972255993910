.container {
  background-color: var(--banner);
  max-height: 150px;
  height: 30vh;
  min-height: 150px;
  display: flex;
  color: white;
}
/*   
.titleContainer {
  margin-left: clamp(40px, 10vw, 10vw);
  min-width: 540px;
  display: flex;
} */

/* .navContainer {
  margin-left: 5vw;
  width: 40vw;
  min-width: 500px;
  margin-top: auto;
} */


.containerGrid {
  background-color: var(--banner);
  display: flex;
  min-height: 165px;
  max-height: 165px;
}

.logoContainer {
  max-height: 165px;
  max-width: 360px;
  min-width: 345px;
  overflow: hidden;
  /* display: flex; */
  /* position: relative; */
}

.lines {
  transform: rotate(57deg);
}

.logo {
  display: block;
  object-fit: contain;
  max-width: 345px;
  /* padding: 0.5em; */
  transform: scaleX(-1);
  position: absolute;
  top: 10px;
  left: 20px;
}

.navbar {
  /* float: right; */
  margin-right: 10em;
  min-width: 275px;
  align-self: flex-end;
  margin-top: auto;
}

.headerContent {
  width: 80%;
  display: flex;
  flex-direction:column;
  flex: 1;
}

.titleContainer {
  display: flex;
  align-items: center;
  padding-left: 3em;
}

.title {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #E2F0F3;
  text-shadow: 0px 5px 4px #00000040;
}

.titleUpper, .titleLower {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 48px;
  font-weight: 800;
}

.titleUpper {
  margin-left: 0.26em;
}

.titleLower {
  margin-top: -0.4em;
}

.siteDescriptionContainer {
  max-width: 320px;
  min-width: 280px;
  max-height: 70px;
  margin-top: auto;
  margin-right: 15em;
  align-self: flex-end;
}

.siteDescription {
  font-family: "Inter";
  color:#E2F0F3;
  font-size: 20px;
}